<template>
  <div class="page6">
    <Row class="content">
      <Col :span="24">
        <div class="map">
          <baidu-map class="model" :center="map.center" :zoom="map.zoom" :mapStyle="mapStyle" @ready="handlerMap">
            <!-- 坐标点   -->
            <bm-marker
              v-for="(item, index) in list"
              :key="index"
              :dragging="true"
              :position="{ lng: item.LONGITUDE, lat: item.LATITUDE }"
              @mouseover="lookDetail(item)"
              @mouseout="closeDetail(item)"
            >
              <!--  @click="lookDetail(item)" -->
              <!-- 坐标点信息提示窗口 -->
              <bm-info-window
                ref="infoWindow"
                :show="item.makerFlag"
                :position="{ lng: infoWindow.LONGITUDE, lat: infoWindow.LATITUDE }"
                @close="infoWindowClose(item)"
                @open="infoWindowOpen(item)"
              >
                <div class="box">
                  <div class="title">{{ infoWindow.COMPNAME }}</div>
                  <div class="address">{{ infoWindow.ADDRESS }}</div>
                  <div class="phone">{{ infoWindow.PHONE }}</div>
                </div>
              </bm-info-window>
            </bm-marker>
          </baidu-map>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
import { getNodecl } from '@/api/home'

export default {
  name: 'page6',
  data() {
    return {
      infoWindow: {},
      map: {
        center: { lng: 119.611633, lat: 39.95869 }, // 秦皇岛经纬度
        zoom: 10,
        show: true,
        dragging: true,
        radius: 3000,
        MapType: 'BMAP_SATELLITE_MAP'
      },
      mapStyle: {
        styleJson: [
          {
            featureType: 'land',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              color: '#091220ff'
            }
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              color: '#113549ff'
            }
          },
          {
            featureType: 'green',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              color: '#0e1b30ff'
            }
          },
          {
            featureType: 'building',
            elementType: 'geometry',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'building',
            elementType: 'geometry.topfill',
            stylers: {
              color: '#113549ff'
            }
          },
          {
            featureType: 'building',
            elementType: 'geometry.sidefill',
            stylers: {
              color: '#143e56ff'
            }
          },
          {
            featureType: 'building',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#dadada00'
            }
          },
          {
            featureType: 'subwaystation',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              color: '#113549B2'
            }
          },
          {
            featureType: 'education',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              color: '#12223dff'
            }
          },
          {
            featureType: 'medical',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              color: '#12223dff'
            }
          },
          {
            featureType: 'scenicspots',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              color: '#12223dff'
            }
          },
          {
            featureType: 'highway',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              weight: 4
            }
          },
          {
            featureType: 'highway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'highway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#fed66900'
            }
          },
          {
            featureType: 'highway',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'highway',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'highway',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'highway',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'arterial',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              weight: 2
            }
          },
          {
            featureType: 'arterial',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'arterial',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffeebb00'
            }
          },
          {
            featureType: 'arterial',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'arterial',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'arterial',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'local',
            elementType: 'geometry',
            stylers: {
              visibility: 'on',
              weight: 1
            }
          },
          {
            featureType: 'local',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'local',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'local',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'local',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#979c9aff'
            }
          },
          {
            featureType: 'local',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffffff'
            }
          },
          {
            featureType: 'railway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'subway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              weight: 1
            }
          },
          {
            featureType: 'subway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#d8d8d8ff'
            }
          },
          {
            featureType: 'subway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'subway',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'subway',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#979c9aff'
            }
          },
          {
            featureType: 'subway',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffffff'
            }
          },
          {
            featureType: 'continent',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'continent',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'continent',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'continent',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'city',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'city',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'city',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'city',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'town',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'town',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'town',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#454d50ff'
            }
          },
          {
            featureType: 'town',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffffff'
            }
          },
          {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'poilabel',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'districtlabel',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'road',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'district',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'poilabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'poilabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'poilabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'manmade',
            elementType: 'geometry',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'districtlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffffff'
            }
          },
          {
            featureType: 'entertainment',
            elementType: 'geometry',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'shopping',
            elementType: 'geometry',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'nationalway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '6'
            }
          },
          {
            featureType: 'nationalway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '7'
            }
          },
          {
            featureType: 'nationalway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '8'
            }
          },
          {
            featureType: 'nationalway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '9'
            }
          },
          {
            featureType: 'nationalway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '10'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '6'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '7'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '8'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '9'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '10'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '6'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '7'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '8'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '9'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,10',
              level: '10'
            }
          },
          {
            featureType: 'cityhighway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '6'
            }
          },
          {
            featureType: 'cityhighway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '7'
            }
          },
          {
            featureType: 'cityhighway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '8'
            }
          },
          {
            featureType: 'cityhighway',
            stylers: {
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '9'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '6'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '7'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '8'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'geometry',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '9'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '6'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '7'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '8'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'labels',
            stylers: {
              visibility: 'off',
              curZoomRegionId: '0',
              curZoomRegion: '6,9',
              level: '9'
            }
          },
          {
            featureType: 'subwaylabel',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'subwaylabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'tertiarywaysign',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'tertiarywaysign',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'provincialwaysign',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'provincialwaysign',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'nationalwaysign',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'nationalwaysign',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'highwaysign',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'highwaysign',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'village',
            elementType: 'labels',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'district',
            elementType: 'labels.text',
            stylers: {
              fontsize: 20
            }
          },
          {
            featureType: 'district',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'district',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'country',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'country',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'tertiaryway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'tertiaryway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff10'
            }
          },
          {
            featureType: 'provincialway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'provincialway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'highway',
            elementType: 'labels.text',
            stylers: {
              fontsize: 20
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'nationalway',
            elementType: 'labels.text',
            stylers: {
              fontsize: 20
            }
          },
          {
            featureType: 'provincialway',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'provincialway',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'provincialway',
            elementType: 'labels.text',
            stylers: {
              fontsize: 20
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'labels.text',
            stylers: {
              fontsize: 20
            }
          },
          {
            featureType: 'cityhighway',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'estate',
            elementType: 'geometry',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'tertiaryway',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'tertiaryway',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'fourlevelway',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'fourlevelway',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'scenicspotsway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'scenicspotsway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'universityway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'universityway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'vacationway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'vacationway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'fourlevelway',
            elementType: 'geometry',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'fourlevelway',
            elementType: 'geometry.fill',
            stylers: {
              color: '#12223dff'
            }
          },
          {
            featureType: 'fourlevelway',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'transportationlabel',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'transportationlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'transportationlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'transportationlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'educationlabel',
            elementType: 'labels',
            stylers: {
              visibility: 'on'
            }
          },
          {
            featureType: 'educationlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'educationlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'educationlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'transportation',
            elementType: 'geometry',
            stylers: {
              color: '#113549ff'
            }
          },
          {
            featureType: 'airportlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'airportlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'scenicspotslabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'scenicspotslabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'medicallabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'medicallabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'medicallabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'scenicspotslabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'airportlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'entertainmentlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'entertainmentlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'entertainmentlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'estatelabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'estatelabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'estatelabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'businesstowerlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'businesstowerlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'businesstowerlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'companylabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'companylabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'companylabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'governmentlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'governmentlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'governmentlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'restaurantlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'restaurantlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'restaurantlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'hotellabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'hotellabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'hotellabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'shoppinglabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'shoppinglabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'shoppinglabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'lifeservicelabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'lifeservicelabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'lifeservicelabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'carservicelabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'carservicelabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'carservicelabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'financelabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'financelabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'financelabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'otherlabel',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'otherlabel',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'otherlabel',
            elementType: 'labels.icon',
            stylers: {
              visibility: 'off'
            }
          },
          {
            featureType: 'manmade',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'manmade',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'transportation',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'transportation',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'education',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'education',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'medical',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'medical',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          },
          {
            featureType: 'scenicspots',
            elementType: 'labels.text.fill',
            stylers: {
              color: '#2dc4bbff'
            }
          },
          {
            featureType: 'scenicspots',
            elementType: 'labels.text.stroke',
            stylers: {
              color: '#ffffff00'
            }
          }
        ]
      },
      list: [
        {
          makerFlag: false,
          LONGITUDE: 119.542041,
          LATITUDE: 39.826621,
          COMPNAME: '碧螺塔海上酒吧公园',
          ADDRESS: '秦皇岛市北戴河区东海滩路中段',
          PHONE: '16603373732'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.31487,
          LATITUDE: 39.642311,
          COMPNAME: '昌黎国际滑沙中心',
          ADDRESS: '河北秦皇岛市昌黎县沿海路15号',
          PHONE: '0335-7800011'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.630369,
          LATITUDE: 39.919438,
          COMPNAME: '公主号游船',
          ADDRESS: '河北省秦皇岛市海港区秦皇求仙入海处南门附近',
          PHONE: '15032368899 / 15032338866'
        },
        { makerFlag: false, LONGITUDE: 119.077576, LATITUDE: 39.820339, COMPNAME: '左右山谷', ADDRESS: '河北省秦皇岛市卢龙县友谊路', PHONE: '0335-7023888' },
        {
          makerFlag: false,
          LONGITUDE: 119.57862,
          LATITUDE: 40.138537,
          COMPNAME: '傍水崖景区',
          ADDRESS: '河北省秦皇岛市海港区石门寨镇北未庄村S251北未庄村南500米',
          PHONE: '0335-8880060'
        },
        {
          makerFlag: false,
          LONGITUDE: 118.699865,
          LATITUDE: 39.974413,
          COMPNAME: '天元谷',
          ADDRESS: '迁安市燕山南路与滨湖东路交汇处西南角',
          PHONE: '0315-2420101/5671666'
        },
        { makerFlag: false, LONGITUDE: 118.537089, LATITUDE: 39.98276, COMPNAME: '山叶口', ADDRESS: '河北省唐山市迁安市大五里乡迎宾路', PHONE: '0315-7088880' },
        { makerFlag: false, LONGITUDE: 118.740091, LATITUDE: 40.200063, COMPNAME: '白羊峪', ADDRESS: '迁安市大崔庄镇白羊峪村', PHONE: '0315-5029702' },
        { makerFlag: false, LONGITUDE: 118.46737, LATITUDE: 40.012925, COMPNAME: '塔寺峪', ADDRESS: '迁安市大五里乡尚庄子村西', PHONE: '0315-7682888' },
        {
          makerFlag: false,
          LONGITUDE: 118.625394,
          LATITUDE: 40.03,
          COMPNAME: '亚滦湾桃源里',
          ADDRESS: '迁安市京秦高速迁安北出口西行800米',
          PHONE: '0315-7899152'
        },
        {
          makerFlag: false,
          LONGITUDE: 118.680171,
          LATITUDE: 39.995739,
          COMPNAME: '瑞美湖心岛营地',
          ADDRESS: '滦河文化产业园区滨湖东路黄台湖10号',
          PHONE: '19931388312'
        },
        { makerFlag: false, LONGITUDE: 118.572407, LATITUDE: 40.06036, COMPNAME: '金岭矿山传奇景区', ADDRESS: '迁安市蔡园镇刘庄村东', PHONE: '0315-5966888' },
        { makerFlag: false, LONGITUDE: 118.459539, LATITUDE: 39.93908, COMPNAME: '成山景区', ADDRESS: '迁安市太平庄镇尚庄村', PHONE: '15233388160' },
        {
          makerFlag: false,
          LONGITUDE: 118.437572,
          LATITUDE: 40.603536,
          COMPNAME: '花溪城水世界',
          ADDRESS: '河北省承德市宽城满族自治县马架沟村出海路',
          PHONE: '0314-6958888'
        },
        {
          makerFlag: false,
          LONGITUDE: 118.335238,
          LATITUDE: 40.626512,
          COMPNAME: '清河源憨宝儿萌宠乐园',
          ADDRESS: '河北省承德市宽城满族自治县塌山乡尖宝山村',
          PHONE: '0314-6951999'
        },
        {
          makerFlag: false,
          LONGITUDE: 118.337732,
          LATITUDE: 40.627351,
          COMPNAME: '清河源高山峡谷漂流',
          ADDRESS: '河北省承德市宽城满族自治县塌山乡尖宝山村',
          PHONE: '0314-6951999'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.527788,
          LATITUDE: 39.876619,
          COMPNAME: '秦皇岛野生动物园',
          ADDRESS: '秦皇岛市北戴河区滨海大道中段62号',
          PHONE: '0335-4182205/4182208'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.530082,
          LATITUDE: 39.84303,
          COMPNAME: '鸽子窝公园',
          ADDRESS: '河北省秦皇岛市北戴河区鸽赤路25号',
          PHONE: '0335-4041774'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.542653,
          LATITUDE: 39.833223,
          COMPNAME: '浪淘沙号游船',
          ADDRESS: '秦皇岛市北戴河区东沙滩路8-1号',
          PHONE: '0335-4042225'
        },
        { makerFlag: false, LONGITUDE: 119.520372, LATITUDE: 39.831436, COMPNAME: '秦皇宫', ADDRESS: '河北省秦皇岛市北戴河区联峰路85号', PHONE: '18000698183' },
        {
          makerFlag: false,
          LONGITUDE: 119.407111,
          LATITUDE: 39.79284,
          COMPNAME: '哒哒岛主题公园',
          ADDRESS: '河北省秦皇岛市抚宁区滨海大道南戴河片区',
          PHONE: '0335-7828881'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.465752,
          LATITUDE: 39.82962,
          COMPNAME: '联峰山公园',
          ADDRESS: '河北省秦皇岛市北戴河区联峰路与剑秋路交叉口',
          PHONE: '0335-4041591'
        },
        {
          makerFlag: false,
          LONGITUDE: 118.830153,
          LATITUDE: 40.211735,
          COMPNAME: '御水湾温泉水上乐园',
          ADDRESS: '河北省秦皇岛市青龙满族自治县肖营子温泉村',
          PHONE: '0335-7767666/18733499966'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.365547,
          LATITUDE: 40.134467,
          COMPNAME: '花果山景区',
          ADDRESS: '秦皇岛市青龙满族自治县高速下口花果山村内',
          PHONE: '18633500808'
        },
        { makerFlag: false, LONGITUDE: 119.188851, LATITUDE: 39.740976, COMPNAME: '华夏酒庄', ADDRESS: '昌黎县城北，昌抚公路西侧', PHONE: '0335-7169888' },
        {
          makerFlag: false,
          LONGITUDE: 119.329749,
          LATITUDE: 39.674447,
          COMPNAME: '圣蓝海洋公园',
          ADDRESS: '秦皇岛市昌黎县大蒲河镇昌海大道3号',
          PHONE: '0335-7815189'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.372531,
          LATITUDE: 40.110746,
          COMPNAME: '冰塘峪旅游区',
          ADDRESS: '秦皇岛市抚宁区大新寨镇梁家湾村东',
          PHONE: '0335-7525999'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.405589,
          LATITUDE: 40.036774,
          COMPNAME: '乱刀峪景区',
          ADDRESS: '河北省秦皇岛市抚宁区大新寨镇大石窟村',
          PHONE: '0335-7833333'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.406551,
          LATITUDE: 40.094121,
          COMPNAME: '背牛顶旅游区',
          ADDRESS: '河北省秦皇岛市抚宁区大新寨镇大石窟村背牛顶旅游区',
          PHONE: '0335-7833333'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.356641,
          LATITUDE: 40.073011,
          COMPNAME: '龙云谷景区',
          ADDRESS: '河北省秦皇岛市抚宁区大新寨镇张各庄村村东',
          PHONE: '0335-6223777'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.36125,
          LATITUDE: 39.741552,
          COMPNAME: '南戴河国际娱乐中心',
          ADDRESS: '河北省秦皇岛市抚宁区南戴河旅游度假区环海路999号',
          PHONE: '0335-4082333'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.258466,
          LATITUDE: 39.870819,
          COMPNAME: '紫金湾游乐场',
          ADDRESS: '秦皇岛市抚宁区紫荆路紫金湾·景彩小区西门西南侧约90米',
          PHONE: '0335-7185555'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.766431,
          LATITUDE: 40.017202,
          COMPNAME: '天下第一关(联票)',
          ADDRESS: '河北省秦皇岛市山海关区东大街1号',
          PHONE: '0335-5258000'
        },
        // { makerFlag: false, LONGITUDE: 119.762456, LATITUDE: 40.015002, COMPNAME: '钟鼓楼', ADDRESS: '', PHONE: '' },
        // { makerFlag: false, LONGITUDE: 119.766893, LATITUDE: 40.009651, COMPNAME: '望洋楼', ADDRESS: '', PHONE: '' },
        // { makerFlag: false, LONGITUDE: 119.756892, LATITUDE: 40.012841, COMPNAME: '迎恩楼', ADDRESS: '', PHONE: '' },
        // { makerFlag: false, LONGITUDE: 119.765745, LATITUDE: 40.01327, COMPNAME: '王家大院', ADDRESS: '河北省秦皇岛市山海关区古城东三条29号', PHONE: '' },
        {
          makerFlag: false,
          LONGITUDE: 119.804427,
          LATITUDE: 39.974589,
          COMPNAME: '老龙头',
          ADDRESS: '河北省秦皇岛市山海关区老龙头路1号',
          PHONE: '0335-5258000'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.806642,
          LATITUDE: 39.973431,
          COMPNAME: '求仙6号游船',
          ADDRESS: '秦皇岛市山海关区老龙头路1号',
          PHONE: '0335-5152225'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.829416,
          LATITUDE: 40.042677,
          COMPNAME: '孟姜女庙景区',
          ADDRESS: '河北省秦皇岛市山海关区望夫石村',
          PHONE: '0335-5153159/5258000'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.709879,
          LATITUDE: 40.049519,
          COMPNAME: '燕塞湖鸟语林景区',
          ADDRESS: '秦皇岛市山海关区小陈庄北',
          PHONE: '0335-5158828'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.746441,
          LATITUDE: 40.073109,
          COMPNAME: '国家地质公园长寿山景区',
          ADDRESS: '秦皇岛市山海关区三道关村',
          PHONE: '0335-5055998'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.569442,
          LATITUDE: 39.911905,
          COMPNAME: '新澳海底世界',
          ADDRESS: '河北省秦皇岛市海港区河滨路81号',
          PHONE: '0335-7826666'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.473407,
          LATITUDE: 39.913924,
          COMPNAME: '秦宇宙(园博园)',
          ADDRESS: '河北省秦皇岛市经济技术开发区园博道1号',
          PHONE: '0335-3457000'
        },
        {
          makerFlag: false,
          LONGITUDE: 119.572246,
          LATITUDE: 39.915297,
          COMPNAME: '秦皇星际小蚂蚁乐园',
          ADDRESS: '河北省秦皇岛市海港区文涛路2号',
          PHONE: '0335-8368728'
        }
      ]
    }
  },
  created() {
    this.getNodecl()
  },
  methods: {
    getNodecl() {
      getNodecl().then((res) => {
        this.list = res.data
      })
    },
    lookDetail(marker) {
      console.log('marker', marker)

      marker.makerFlag = true
      this.infoWindow = marker
    },
    closeDetail(marker) {
      marker.makerFlag = false
    },
    infoWindowClose(marker) {
      marker.makerFlag = false
    },
    infoWindowOpen(marker) {
      marker.makerFlag = true
    },
    handlerMap({ map }) {
      // this.BMap = map

      // 鼠标缩放
      map.enableScrollWheelZoom(true)

      // 圆形覆盖物相关逻辑
      // const radius = this.map.radius
      // for (const item of this.list) {
      //   const circle = new this.$baidu.BMap.Circle(new this.$baidu.BMap.Point(item.LONGITUDE, item.LATITUDE), radius, {
      //     strokeColor: 'blue',
      //     fillOpacity: 0.3,
      //     strokeWeight: 1,
      //     strokeOpacity: 0.5
      //   })
      //   circle.setRadius(radius, true)
      //   map.addOverlay(circle)

      //   const polyline = new this.$baidu.BMap.Polyline(
      //     [new this.$baidu.BMap.Point(item.LONGITUDE, item.LATITUDE), new this.$baidu.BMap.Point(item.LONGITUDE, item.LATITUDE)],
      //     {
      //       strokeColor: 'red',
      //       strokeStyle: 'dashed',
      //       strokeWeight: 2,
      //       strokeOpacity: 0.5
      //     }
      //   )
      //   map.addOverlay(polyline)
      // }
      // 设置圆形覆盖物可编辑
      // circle.enableEditing()
      // 点击事件获取经纬度
      // map.addEventListener('click', function (e) {
      //   /*me.map.show = true*/
      //   console.log(e)
      //   map.openInfoWindow()
      // })
    }
  }
}
</script>

<style lang="less" scoped>
.page6 {
  height: 100%;
  width: 100%;
  overflow: hidden;
  .content {
    height: 100%;
    position: relative;
    .map {
      height: 100%;
      position: relative;
      .model {
        height: 100%;
        position: relative;

        .box {
          height: 100%;
          margin-top: 20px;
          font-size: 10px;

          .title {
            width: 100%;
            height: 40px;
            font-size: 12px;
            font-weight: 700;
            color: #cc5522;
            text-align: left;
            background-color: transparent;
          }
          .address {
            text-wrap: nowrap;
          }
        }
      }
    }
  }
}
</style>
